import React from "react"
import get from 'lodash/get'
import filter from 'lodash/filter'
import { graphql } from 'gatsby'
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { Manifest } from "../components/sections"

const WhyPageTemplate = ({ pageContext, data, location }) => {
  const seoData = get(data, 'sanityWhyPage.seo')
  const footerData = get(data, 'sanityFooter')
  const contentData = get(data, 'sanityWhyPage.content') || []

  const focusKeyword = get(seoData, 'seoTools.focus_keyword')
  const synonyms = get(seoData, 'seoTools.focus_synonyms') || []

  const locale = get(pageContext, 'locale')

  const [manifestSection] = filter(contentData, { '_type': 'manifestSection' })

  return (
    <>
      <SEO
        title={get(seoData, 'seoTools.seo_title')}
        description={get(seoData, 'seoTools.meta_description')}
        lang={locale}
        pathname={get(location, 'pathname')}
        image={get(seoData, 'seoThumbnail.asset.localFile.childImageSharp.resize')}
        keywords={[focusKeyword, ...synonyms]}
      />
      <Layout locale={get(pageContext, 'locale')} footer footerContent={footerData}>
        <Manifest content={manifestSection} />
      </Layout>
    </>
  )
}

export default WhyPageTemplate

export const query = graphql`
  query WhyPage($slug: String, $locale: String) {
    sanityWhyPage(essentials: {slug: {current: {eq: $slug}}, locale: {eq: $locale}}) {
      seo {
        seoThumbnail {
          asset {
            localFile {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        seoTools {
          focus_keyword
          focus_synonyms
          meta_description
          seo_title
        }
      }
      essentials {
        locale
        slug {
          current
        }
      }
      content {
        ... on SanityManifestSection {
          _key
          _type
          subline
          initial
          headline
          _rawText(resolveReferences: {maxDepth: 10})
        }
      }
    }
    sanityFooter(locale: {eq: $locale}) {
      copyright
      languageSwitcherLabel
      mission
      links {
        _key
        headline
        links {
          _key
          label
          slug
        }
      }
      newsletter {
        headline
        subline
        buttonText
        emailField {
          type
          placeholder
          label
          identifier
          errors {
            _key
            key
            message
          }
        }
        postSubmitRedirectSlug
        toastError {
          headline
          description
        }
      }
      socialLinks {
        _key
        profileUrl
        type
      }
      logo {
        asset {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
